// import { Layout } from 'react-grid-layout'

type Layout = any

export type DataValue = string | number | boolean | null

export interface FlatData {
  [key: string]: DataValue | FlatData | Array<FlatData>
}

export interface FormFieldPages {
  [page: number]: FormField[]
}

export interface BaseFormField {
  guid?: string
  name: string
  jsonPath: string
  key: string // Unique ID for this field - generated on the UI and generally used for layouting purposes.
  placeholder?: string
  helperText?: string
  isRequired: boolean
  isReadonly?: boolean
  layout?: Layout
  options?: string[]
  tabIndex?: number
  listIndex?: number
  hideOnPrint?: boolean
  defaultValue?: DataValue
}

export enum FormFieldTypes {
  // String
  PlainTextInput = 'Plain Text Input',
  Textarea = 'Textarea',
  Email = 'Email',
  PhoneNumber = 'Phone Number',
  URL = 'URL',
  Secret = 'Secret',
  Select = 'Select',
  MultiSelect = 'Multi Select',
  Address = 'Address',
  // Boolean
  Switch = 'Switch',
  Checkbox = 'Checkbox',
  YesNo = 'Yes/No',
  // Number
  Currency = 'Currency',
  NumericInput = 'Numeric Input',
  Slider = 'Slider',
  // Others
  Datepicker = 'Datepicker',
  DateRangePicker = 'Date Range Picker',
  Markdown = 'Markdown',
  Table = 'Table',
  JSON = 'JSON',
  IconPicker = 'Icon Picker',
  GroupedInputs = 'Grouped Inputs',
  APILookup = 'API Lookup',
  // Layout
  Header = 'Header',
  Divider = 'Divider',
  StaticMarkdown = 'Static Markdown',
  StaticImage = 'Static Image',
}

export const STRING_FIELD_TYPES = [
  FormFieldTypes.PlainTextInput,
  FormFieldTypes.Textarea,
  FormFieldTypes.Email,
  FormFieldTypes.URL,
  FormFieldTypes.Secret,
  FormFieldTypes.PhoneNumber,
]
export const NUMBER_FIELD_TYPES = [FormFieldTypes.NumericInput, FormFieldTypes.Currency, FormFieldTypes.Slider]
export type StringFormFieldTypes = (typeof STRING_FIELD_TYPES)[number]
export type NumberFormFieldTypes = (typeof NUMBER_FIELD_TYPES)[number]

export interface BasicField extends BaseFormField {
  type:
    | FormFieldTypes.PlainTextInput
    | FormFieldTypes.Textarea
    | FormFieldTypes.Email
    | FormFieldTypes.PhoneNumber
    | FormFieldTypes.Secret
    | FormFieldTypes.Switch
    | FormFieldTypes.Checkbox
    | FormFieldTypes.Markdown
    | FormFieldTypes.JSON
    | FormFieldTypes.Select
}

export interface URLField extends BaseFormField {
  type: FormFieldTypes.URL
  imageUrl?: string | null
}

export interface YesNoFieldType extends BaseFormField {
  type: FormFieldTypes.YesNo
  defaultValue?: boolean | null
}

export interface NumericField extends BaseFormField {
  type: FormFieldTypes.NumericInput
  minimumNumber?: number
  maximumNumber?: number
  step?: number
  formula?: string
}

export interface CurrencyField extends BaseFormField {
  type: FormFieldTypes.Currency
  minimumNumber?: number
  maximumNumber?: number
  formula?: string
}

export interface SliderFieldType extends BaseFormField {
  type: FormFieldTypes.Slider
  minimumNumber?: number
  maximumNumber?: number
  step?: number
  prefix?: string
  suffix?: string
  restrictedValues?: number[]
}

export interface SelectField extends BaseFormField {
  type: FormFieldTypes.Select | FormFieldTypes.MultiSelect
  groupedPageRefId?: string
  options: string[]
}

export interface MultiSelectField extends BaseFormField {
  type: FormFieldTypes.MultiSelect
  groupedPageRefId?: string
  options: string[]
  maxOptions?: number
}
export interface DatepickerField extends BaseFormField {
  type: FormFieldTypes.Datepicker
  minimumDate?: Date
  maximumDate?: Date
  pastDatesEnabled: boolean
  futureDatesEnabled: boolean
  isDefaultDateToday?: boolean
}

export interface DateRangePickerField extends BaseFormField {
  type: FormFieldTypes.DateRangePicker
  minimumDate?: Date
  maximumDate?: Date
}

export interface TableColumn {
  columnName: string
  displayName?: string
  columnType: string
  options?: string[]
}

export interface ColumnType {
  label:
    | FormFieldTypes.PlainTextInput
    | FormFieldTypes.Email
    | FormFieldTypes.PhoneNumber
    | FormFieldTypes.URL
    | FormFieldTypes.Secret
    | FormFieldTypes.Switch
    | FormFieldTypes.Checkbox
    | NumberFormFieldTypes
    | FormFieldTypes.Datepicker
    | FormFieldTypes.Select
  value:
    | FormFieldTypes.PlainTextInput
    | FormFieldTypes.Email
    | FormFieldTypes.PhoneNumber
    | FormFieldTypes.URL
    | FormFieldTypes.Secret
    | FormFieldTypes.Switch
    | FormFieldTypes.Checkbox
    | NumberFormFieldTypes
    | FormFieldTypes.Datepicker
    | FormFieldTypes.Select
}

export interface TableField extends BaseFormField {
  type: FormFieldTypes.Table
  columns: TableColumn[]
}

export interface GroupedInputsField extends BaseFormField {
  type: FormFieldTypes.GroupedInputs
  fields: FieldEntry[]
}

export interface APILookupField extends BaseFormField {
  type: FormFieldTypes.APILookup
  buttonLabel: string
  apiUrl: string
  apiRequestHeaders?: any
  lookupMapping: APIResponseMapping[]
  queryParams: QueryParamMapping[]
}

export interface APIResponseMapping {
  sourceJsonpath: string
  targetFormFieldId: string
}

export interface QueryParamMapping {
  queryParamKey: string
  targetFormFieldId: string
}

export interface FieldEntry {
  uuid: string
  fieldName: string // This is the jsonpath/key of the field
  displayName?: string
  placeholder?: string
  fieldType: FormFieldTypes
  options?: string[]
  layout: Layout
}

export interface AddressField extends BaseFormField {
  type: FormFieldTypes.Address
  streetAddressJsonPath: string
  streetAddressLine2JsonPath: string
  cityJsonPath: string
  stateProvinceJsonPath: string
  countryJsonPath: string
  postalCodeJsonPath: string
  countryRestrictions?: string[]
}

export enum AddressFieldKeys {
  STREET_ADDRESS = 'street_address',
  STREET_ADDRESS_2 = 'street_address-line-2',
  CITY = 'city',
  STATE_OR_PROVINCE = 'state-province',
  COUNTRY = 'country',
  POSTAL_ZIP_CODE = 'postal-zip-code',
  IS_MANUALLY_ENTERED = 'is_manually_entered',
}

export const READABLE_ADDRESS_FIELDS: Record<AddressFieldKeys, string> = {
  [AddressFieldKeys.STREET_ADDRESS]: 'Street Address',
  [AddressFieldKeys.STREET_ADDRESS_2]: 'Street Address Line 2',
  [AddressFieldKeys.CITY]: 'City',
  [AddressFieldKeys.STATE_OR_PROVINCE]: 'State',
  [AddressFieldKeys.COUNTRY]: 'Country',
  [AddressFieldKeys.POSTAL_ZIP_CODE]: 'Postal Code',
  [AddressFieldKeys.IS_MANUALLY_ENTERED]: 'Manually Entered',
}

export interface IconOption {
  icon: string
  label: string
  boxSize?: number
  helperText?: string
  jsonPath: string
}

export interface IconPickerField extends BaseFormField {
  type: FormFieldTypes.IconPicker
  icons: IconOption[]
  multiselect: boolean
  hideLabels: boolean
  boxSize: number
  cellsPerRow: number
  outputAsBoolean: boolean
}

export interface HeaderField {
  guid?: string
  type: FormFieldTypes.Header
  name: string
  key: string
  hasDivider: boolean
  size?: 'sm' | 'xs' | 'xxs'
  layout: Layout
  listIndex?: number
  showOnlyOnPrint?: boolean
  hideOnPrint?: boolean
}

export interface StaticMarkdownField {
  guid?: string
  type: FormFieldTypes.StaticMarkdown
  key: string
  content: string
  layout: Layout
  listIndex?: number
  showOnlyOnPrint?: boolean
  hideOnPrint?: boolean
}

export interface StaticImageField {
  guid?: string
  type: FormFieldTypes.StaticImage
  key: string
  url: string
  layout: Layout
  listIndex?: number
  link: {
    href?: string
    isInternal?: boolean
  }
  showOnlyOnPrint?: boolean
  hideOnPrint?: boolean
}

export interface DividerField {
  guid?: string
  type: FormFieldTypes.Divider
  key: string
  layout: Layout
  listIndex?: number
  hideOnPrint?: boolean
  showOnlyOnPrint?: boolean
}

export type FormField = (
  | BasicField
  | URLField
  | YesNoFieldType
  | NumericField
  | CurrencyField
  | SliderFieldType
  | SelectField
  | MultiSelectField
  | DatepickerField
  | DateRangePickerField
  | HeaderField
  | TableField
  | DividerField
  | StaticMarkdownField
  | StaticImageField
  | AddressField
  | IconPickerField
  | GroupedInputsField
  | APILookupField
) & {
  /**
   * internal property
   */
  baseFieldId?: string
}

const DEFAULT_SIZE_CONFIG: Partial<Layout> = {
  w: 4,
  h: 1,
  minW: 3,
  minH: 1,
}

export const FIELD_DEFAULT_SIZE_MAP: Record<FormFieldTypes, Partial<Layout>> = {
  [FormFieldTypes.Markdown]: {
    w: 6,
    h: 3,
    minW: 3,
    minH: 3,
  },
  [FormFieldTypes.Table]: {
    w: 12,
    h: 4,
    minH: 3,
    minW: 5,
  },
  [FormFieldTypes.PlainTextInput]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.APILookup]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.JSON]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.Textarea]: {
    w: 4,
    h: 2,
    minW: 3,
    minH: 3,
  },
  [FormFieldTypes.Email]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.PhoneNumber]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.URL]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.Secret]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.Select]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.MultiSelect]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.Switch]: {
    w: 3,
    h: 1,
    minW: 2,
  },
  [FormFieldTypes.Checkbox]: {
    w: 3,
    h: 1,
    minW: 2,
  },
  [FormFieldTypes.Currency]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.NumericInput]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.Slider]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.Datepicker]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.DateRangePicker]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.Header]: {
    w: 4,
    h: 1,
    minW: 2,
    minH: 1,
  },
  [FormFieldTypes.Divider]: {
    w: 4,
    h: 1,
    minW: 2,
    minH: 1,
  },
  [FormFieldTypes.StaticMarkdown]: {
    w: 6,
    h: 3,
    minW: 2,
    minH: 1,
  },
  [FormFieldTypes.StaticImage]: DEFAULT_SIZE_CONFIG,
  [FormFieldTypes.Address]: {
    w: 12,
    h: 5,
    minW: 12,
    minH: 5,
  },
  [FormFieldTypes.YesNo]: {
    w: 4,
    h: 1,
    minW: 2,
  },
  [FormFieldTypes.IconPicker]: {
    w: 12,
    h: 2,
    minW: 12,
    minH: 2,
  },
  [FormFieldTypes.GroupedInputs]: {
    w: 12,
    h: 6,
    minH: 3,
    minW: 5,
  },
}
