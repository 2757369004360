import {
  Box,
  Step,
  StepIcon,
  StepIndicator,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Tooltip,
} from '@chakra-ui/react'
import { MouseEvent } from 'react'
import { EMPTY_FN } from '../../constants'
import { Page, Style } from '../../interfaces'
import { StyleUtils } from '../../utils'

export const FormStepper = ({
  showName,
  style,
  pages,
  currPage,
  onPageChange,
}: {
  showName: boolean
  style: Style | undefined
  pages: Page[] | undefined
  currPage: Page | undefined
  onPageChange: (change: 'increment' | 'decrement', guid?: string) => (event: MouseEvent<HTMLElement>) => void
  isReadOnly?: boolean
}) => {
  const { colors } = StyleUtils.getTheme(style ?? {})

  return (
    <Stepper
      size="sm"
      index={pages?.findIndex(page => page.guid === currPage?.guid) ?? 0}
      mb={4}
      mx={4}
      p={4}
      sx={{
        '& .chakra-step__title': {
          maxW: '20ch',
        },
        '& > [data-status="active"]': {
          color: colors.primaryColor,
          '& .chakra-step__indicator': {
            borderColor: colors.primaryColor,
          },
        },
        '& > [data-status="complete"]': {
          opacity: 0.6,
          _hover: {
            opacity: 1,
          },
          '& .chakra-step__indicator': {
            bg: colors.primaryColor,
          },
        },
        '& .chakra-step__indicator[data-status=incomplete]': {
          _hover: {
            borderColor: colors.primaryColor,
            opacity: 0.8,
            transition: 'border-color 0.2s ease, opacity 0.2s ease',
          },
        },
        '& .chakra-step__separator[data-status=complete]': {
          backgroundColor: colors.primaryColor,
        },
      }}
    >
      {pages?.map((page, index) => (
        <Step
          key={index}
          onClick={
            currPage && page.order < currPage?.order
              ? onPageChange('decrement', page.guid)
              : onPageChange('increment', page.guid)
          }
        >
          <Tooltip label={!showName ? page.name ?? `Page ${page.order + 1}` : ''} placement="top">
            <Box display="flex" gap="2" cursor="pointer">
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  active={<Box w="2.5" h="2.5" bg={colors.primaryColor} borderRadius="full" />}
                />
              </StepIndicator>
              {showName && (
                <Box flexShrink="0">
                  <StepTitle>{page.name ?? `Page ${page.order + 1}`}</StepTitle>
                </Box>
              )}
            </Box>
          </Tooltip>
          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  )
}
